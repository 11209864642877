import React, { PureComponent } from "react";

import { Error } from "./Error";
import { Logo } from "./Logo";
import { AUTH0_DATABASE_CONNECTION, isEmailMaybe, isEmptyObject } from "../utils";

export class Reset extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            error: undefined,
            formIsSubmitting: false,
            success: undefined
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }

    handleAuthResponse(err) {
        if (!isEmptyObject(err)) {
            this.setState({
                error: err,
                success: undefined
            });
        } else {
            this.setState({
                error: undefined,
                success: true
            })
        }
    }

    handleEmailChange(e) {
        const value = e.currentTarget.value;

        this.setState({
            email: value
        });
    }

    handleReset(e) {
        e.preventDefault();

        const { email } = this.state;
        const handleAuthResponse = this.handleAuthResponse.bind(this);

        this.props.webAuth.changePassword({
            connection: AUTH0_DATABASE_CONNECTION,
            email: email
        }, handleAuthResponse);
    }

    render() {
        const isEmailValid = isEmailMaybe(this.state.email);

        return (
            <>
                <div className="flex-grow flex col">
                    <h1 className="flex-no-grow">
                        <Logo />
                        Reset
                    </h1>

                    <div className="flex flex-grow col guts">
                        <Error error={this.state.error} />

                        {this.renderSuccessMessage()}

                        <form id="reset" onSubmit={this.handleReset} method="post">
                            <fieldset>
                                <label htmlFor="reset_email">Please enter your email address. We will send you an email to reset your password.</label>

                                <input type="email" id="reset_email" name="email" onChange={this.handleEmailChange} placeholder="email@example.com" required value={this.state.email} />
                            </fieldset>

                            <input type="submit" value="Send Email" id="reset_submit" disabled={!isEmailValid || this.state.formIsSubmitting} />
                        </form>
                    </div>

                </div>

                <footer className="flex-no-grow">
                    <p>Just remembered it?<br />
                        <a href="#signin" id="signup_link">Sign In</a>.</p>
                </footer>
            </>
        );
    }

    renderSuccessMessage() {
        if (this.state.success) {
            return (
                <div class="success-message">
                    <h2>Success!</h2>

                    <p> If you have not received an email within a few minutes, it's possible that you signed up with either your Google or Amazon account: in that case, there is no need to reset, simply sign in with that account.<br /> If you still need help, feel free to <a href='https://xappmedia.kayako.com/' target='blank' rel='noopener'>contact support</a>.</p>
                </div>
            );
        }
    }
}
