export * from "./constants";

/**
 * eetermines if an object is 'empty'
 * pulled from https://stackoverflow.com/a/32108184/9529258
 *
 * @export
 * @param {*} obj
 * @returns
 */
export function isEmptyObject(obj) {
    if (obj === undefined || obj === null) {
        return true;
    }

    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

/**
 * detects an email address with 99.9% accuracy
 * pulled from http://emailregex.com
 *
 * @export
 * @param {*} email
 * @returns
 */
export function isEmailMaybe(email) {
    /* eslint-disable no-useless-escape */
    const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
    /* eslint-enable no-useless-escape */

    return EMAIL_REGEX.test(email);
}
