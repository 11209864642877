import React, { PureComponent } from "react";

import { Error } from "./Error";
import { Logo } from "./Logo";
import { UserForm } from "./UserForm";
import { AUTH0_DATABASE_CONNECTION, STENTOR_USER_PASS } from "../utils";


import {
    isEmptyObject,
    STENTOR_AMAZON,
    STENTOR_GOOGLE,
    STENTOR_LOGIN_METHOD,
} from "../utils"

export class Signup extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            confirmUsername: "",
            confirmPass: "",
            currentStep: 1,
            error: undefined,
            formIsSubmitting: false,
            user: {
                hasAcceptedTerms: false,
                username: "",
                password: ""
            }
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTermsChange = this.handleTermsChange.bind(this);
    }

    handleAuthResponse(err) {
        if (!isEmptyObject(err)) {
            this.handleError(err);
        } else {
            if (window.localStorage) {
                window.localStorage.setItem(STENTOR_LOGIN_METHOD, STENTOR_USER_PASS)
            }
        }
    }

    handleError(err) {
        this.setState({
            error: err,
            formIsSubmitting: false,
        });
    }

    handleOAuthLogin(provider) {
        this.props.webAuth.authorize({
            connection: provider
        });

        if (window.localStorage) {
            window.localStorage.setItem(STENTOR_LOGIN_METHOD, provider)
        }
    }

    handleSubmit(newUserData) {
        const handleAuthResponse = this.handleAuthResponse.bind(this);

        this.props.webAuth.redirect.signupAndLogin({
            connection: AUTH0_DATABASE_CONNECTION,
            email: newUserData.email,
            password: newUserData.password
        }, handleAuthResponse);

        if (window.localStorage) {
            window.localStorage.setItem(STENTOR_LOGIN_METHOD, STENTOR_USER_PASS)
        }
    }

    handleStepClick(step) {
        this.setState({ currentStep: step });
    }

    handleTermsChange(e) {
        const hasAcceptedTerms = e.currentTarget.checked;
        const currentStep = hasAcceptedTerms ? 2 : 1;
        const user = {
            ...this.state.user,
            hasAcceptedTerms
        };

        this.setState({ currentStep, user });
    }

    render() {
        const { currentStep, error } = this.state;

        return (
            <>
                <div className="flex-grow flex col">
                    <h1 className="flex-no-grow">
                        <Logo />
                        Sign Up
                    </h1>

                    <div className="flex flex-grow col guts">
                        <Error error={error} />

                        {this.renderCurrentStep(currentStep)}
                    </div>
                </div>

                <footer className="flex-no-grow">
                    <p>Already have a XAPPmedia account?<br />
                    <a href="#signin" id="signup_link">Sign In</a>.</p>
                </footer>
            </>
        );
    }

    renderCurrentStep(currentStep) {
        switch(currentStep) {
            case 2:
                return this.renderStep2();
            case 3:
                return this.renderStep3();
            default:
                return this.renderStep1();
        }
    }

    renderStep1() {
        return (
            <div id="signup_step_1">
                <p>Before you can create an account, you must agree to XAPPmedia's <a href="https://go.xappmedia.com/terms" target="blank" rel="noopener">terms of service</a>.</p>

                <label className="terms">
                    <input type="checkbox" id="accept_terms" onChange={this.handleTermsChange} checked={this.state.user.hasAcceptedTerms} />

                    <span>I agree to the terms of service</span>
                </label>
            </div>
        );
    }

    renderStep2() {
        const { user } = this.state;

        return (
            <div id="social">
                <button className="social amazon" disabled={!user.hasAcceptedTerms} onClick={this.handleOAuthLogin.bind(this, STENTOR_AMAZON)}>Sign up with Amazon</button>
                <button className="social google" disabled={!user.hasAcceptedTerms} onClick={this.handleOAuthLogin.bind(this, STENTOR_GOOGLE)}>Sign up with Google</button>

                <p>or</p>

                <button className="social email" disabled={!user.hasAcceptedTerms} onClick={this.handleStepClick.bind(this, 3)}>Sign up with my email address</button>
            </div>
        );
    }

    renderStep3() {
        return (
            <>
                <UserForm onSubmit={this.handleSubmit} />

                <p>
                    <small><a href="#signup" onClick={this.handleStepClick.bind(this, 2)}>Actually, I think I'd like to see all signup options</a></small>
                </p>
            </>
        );
    }
}
