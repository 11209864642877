import React, { PureComponent } from "react";

import { isEmailMaybe } from "../utils";

export class UserForm extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            confirmPassword: "",
            formIsSubmitting: false,
            username: "",
            password: "",
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            formIsSubmitting: nextProps.formIsSubmitting
        })
    }

    handleChange(key, e) {
        this.setState({
            ...this.state,
            [key]: e.currentTarget.value
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        const { password, username } = this.state;

        this.setState({
            formIsSubmitting: true
        }, () => {
            this.props.onSubmit({email: username, password: password });
        });
    }

    passwordHas8Characters(password) {
        return password.length >= 8;
    }

    passwordHasatoz(password) {
        return password.match(/([a-z])/g) && password.match(/([a-z])/g).length;
    }

    passwordHasAtoZ(password) {
        return password.match(/([A-Z])/g) && password.match(/([A-Z])/g).length;
    }

    passwordHasANumber(password) {
        return password.match(/\d/g) && password.match(/\d/g).length
    }

    passwordHasASpecialCharacter(password) {
        return password.match(/[!@#$%^&*]/g) && password.match(/[!@#$%^&*]/g).length;
    }

    passwordIsValid(password) {
        return this.passwordHas8Characters(password) && this.passwordHasatoz(password) && this.passwordHasAtoZ(password) && this.passwordHasANumber(password) && this.passwordHasASpecialCharacter(password);
    }

    render() {
        const { confirmPassword, password, username } = this.state;
        const emailIsValid = isEmailMaybe(username);
        const passwordIsValid = this.passwordIsValid(password);
        const passwordIsConfirmed = passwordIsValid && confirmPassword === password;

        return (
            <>
                <div id="signup_form">
                    <form id="signup" onSubmit={this.handleSubmit} method="post">
                        <fieldset>
                            <label className="sr-only" htmlFor="signup_username">Email Address</label>

                            <input
                                autoFocus={true}
                                id="signup_username"
                                name="email"
                                onChange={this.handleChange.bind(this, "username")}
                                placeholder="email@example.com"
                                required
                                type="email"
                                value={username} />
                        </fieldset>

                        <fieldset>
                            <label className="sr-only" htmlFor="signup_password">Password</label>

                            <input
                                disabled={!emailIsValid}
                                type="password"
                                id="signup_password"
                                name="password"
                                onChange={this.handleChange.bind(this, "password")}
                                placeholder="create your password"
                                required
                                value={password} />
                        </fieldset>

                        <fieldset>
                            <label className="sr-only" htmlFor="confirm_password">Password</label>
                            <input
                            disabled={!passwordIsValid}
                                id="confirm_password"
                                type="password"
                                name="confirm_password"
                                onChange={this.handleChange.bind(this, "confirmPassword")}
                                placeholder="confirm your password"
                                required
                                value={confirmPassword}/>
                        </fieldset>

                        <button disabled={!passwordIsConfirmed && !emailIsValid} className="submit-button terms-required" id="signup_submit">Sign Up</button>
                    </form>

                    {this.renderProgress()}
                </div>
            </>
        );
    }

    renderCheckMark(renderCheckmark) {
        return renderCheckmark ? String.fromCharCode(10003) : "";
    }

    renderRowClass(isValid) {
        return isValid ? "flex is-valid" : "flex";
    }

    renderProgress() {
        const { confirmPassword, username, password } = this.state;

        return (
            <div>
                <ul className="validation">
                    <li className={this.renderRowClass(isEmailMaybe(username))}>
                        <span className="label">Email</span>
                        <span className="flex-no-grow status">{this.renderCheckMark(isEmailMaybe(username))}</span>
                    </li>

                    <li className="flex col">
                        <div className={this.renderRowClass(this.passwordIsValid(password))}>
                            <span className="label">Password</span>
                            <span className="flex-no-grow status">{this.renderCheckMark(this.passwordHas8Characters(password))}</span>
                       </div>

                        <ul>
                            <li className={this.renderRowClass(this.passwordHasatoz(password))}>
                                <span className="label sub-label">At least 1 lower case letter (a - z)</span>
                            </li>

                            <li className={this.renderRowClass(this.passwordHasAtoZ(password))}>
                                <span className="label sub-label">At least 1 upper case letter (A - Z)</span>
                            </li>

                            <li className={this.renderRowClass(this.passwordHasANumber(password))}>
                                <span className="label sub-label">At least 1 number</span>
                            </li>

                            <li className={this.renderRowClass(this.passwordHasASpecialCharacter(password))}>
                                <span className="label sub-label">At least 1 special character ({'!@#$%^&*'})</span>
                            </li>

                            <li className={this.renderRowClass(this.passwordHas8Characters(password))}>
                                <span className="label sub-label">8 characters minimum</span>
                            </li>
                        </ul>
                    </li>

                    <li className={this.renderRowClass(confirmPassword === password && this.passwordIsValid(password))}>
                        <span className="label">Confirm Password</span>
                        <span className="flex-no-grow status">{this.renderCheckMark(confirmPassword === password && this.passwordIsValid(password))}</span>
                    </li>
                </ul>
            </div>
        );
    }
}
