import React from "react";

import { isEmptyObject } from "../utils";

export const Error = (props) => {
    if (props.error && !isEmptyObject(props.error)) {
        const { error } = props;
        const message = error.description || "There was an error. Please try again";

        return (
            <div className="error-message">
                {message}
            </div>
        );
    }

    return <></>;
}
