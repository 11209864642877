import React, { PureComponent } from "react";

import { Error } from "./Error";
import { Logo } from "./Logo";

import {
    AUTH0_DATABASE_CONNECTION,
    STENTOR_AMAZON,
    STENTOR_GOOGLE,
    STENTOR_LOGIN_METHOD,
    STENTOR_USER_PASS,
    isEmptyObject
} from "../utils";

export class Login extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            error: undefined,
            formIsSubmitting: undefined,
            password: "",
            previousMethod: this.getPreviousLoginMethod(),
            username: ""
        }

        this.handleAmazonLogin = this.handleAmazonLogin.bind(this);
        this.handleAuthResponse = this.handleAuthResponse.bind(this);
        this.handleClearPreviousLoginMethod = this.handleClearPreviousLoginMethod.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
    }

    getPreviousLoginMethod() {
        if (window.localStorage) {
            const previousMethod = window.localStorage.getItem(STENTOR_LOGIN_METHOD);

            switch (previousMethod) {
                case STENTOR_AMAZON:
                case STENTOR_GOOGLE:
                case STENTOR_USER_PASS:
                    return previousMethod;
                default:
                    return undefined;
            }
        }
    }

    handleAmazonLogin() {
        this.setState({
            formIsSubmitting: true
        });

        const handleAuthResponse = this.handleAuthResponse.bind(this);

        this.props.webAuth.authorize({
            connection: STENTOR_AMAZON
        }, handleAuthResponse);

        if (window.localStorage) {
            window.localStorage.setItem(STENTOR_LOGIN_METHOD, STENTOR_AMAZON)
        }
    }

    handleAuthResponse(err) {
        if (!isEmptyObject(err)) {
            this.handleError(err);
        }
    }

    handleClearPreviousLoginMethod() {
        this.setState({
            previousMethod: undefined
        }, () => {
            if (window.localStorage) {
                window.localStorage.setItem(STENTOR_LOGIN_METHOD, undefined);
            }
        });
    }

    handleError(err) {
        this.setState({
            error: err,
            formIsSubmitting: false,
        });
    }

    handleGoogleLogin() {
        this.setState({
            formIsSubmitting: true
        });

        const handleAuthResponse = this.handleAuthResponse.bind(this);

        this.props.webAuth.authorize({
            connection: STENTOR_GOOGLE
        }, handleAuthResponse);

        if (window.localStorage) {
            window.localStorage.setItem(STENTOR_LOGIN_METHOD, STENTOR_GOOGLE)
        }
    }

    handlePasswordChange(e) {
        this.setState({
            password: e.currentTarget.value
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({
            formIsSubmitting: true
        });

        const { username, password } = this.state;
        const handleAuthResponse = this.handleAuthResponse.bind(this);

        this.props.webAuth.login({
            realm: AUTH0_DATABASE_CONNECTION,
            username: username,
            password: password
        }, handleAuthResponse);

        if (window.localStorage) {
            window.localStorage.setItem(STENTOR_LOGIN_METHOD, STENTOR_USER_PASS)
        }
    }

    handleUsernameChange(e) {
        this.setState({
            username: e.currentTarget.value
        });
    }

    render() {
        const { error, previousMethod } = this.state;

        return (
            <>
                <div className="flex-grow flex col">
                    <h1 className="flex-no-grow">
                        <Logo />
                        { previousMethod ? "Welcome back!" : "Log in" }
                    </h1>

                    <div className="flex flex-grow col guts">
                        <Error error={error} />

                        {
                            previousMethod ?
                            this.renderPartialForm(previousMethod) :
                            this.renderAllLoginOptions()
                        }
                    </div>
                </div>

                <div className="flex-no-grow">
                    {this.renderFooter()}
                </div>
            </>
        );
    }

    renderAllLoginOptions() {
        return (
            <>
                <div id="social">
                    {this.renderAmazonButton()}
                    {this.renderGoogleButton()}
                </div>

                <p className="or">or</p>

                {this.renderForm()}
            </>
        );
    }

    renderAmazonButton() {
        const { formIsSubmitting } = this.state;
        const buttonClassNames = formIsSubmitting ? "social amazon is-working" : "social amazon";

        return (
            <button className={buttonClassNames} disabled={formIsSubmitting} onClick={this.handleAmazonLogin}>Log in with Amazon</button>
        );
    }

    renderClearButton() {
        return (
            <p>
                <small><a href="#login" onClick={this.handleClearPreviousLoginMethod}>Actually, I think I'd like to see all login options</a></small>
            </p>
        );
    }

    renderEscapeLink() {
        return (
            <small><a href="#login" onClick={this.handle}>I'd really rather see all available login options</a></small>
        );
    }

    renderForm() {
        const { formIsSubmitting, password, username } = this.state;
        const buttonClassNames = formIsSubmitting ? "submit-button is-working" : "submit-button";

        return (
            <div id="login_form">
                <form id="login" onSubmit={this.handleSubmit} method="post">
                    <fieldset>
                        <label className="sr-only" htmlFor="login_username">Email Address</label>
                        <input disabled={formIsSubmitting} id="login_username" name="email" onChange={this.handleUsernameChange} placeholder="email@example.com" ref={this.usernameRef} required type="email" value={username} />
                    </fieldset>

                    <fieldset>
                        <label className="sr-only" htmlFor="login_password">Password</label>
                        <input disabled={formIsSubmitting} id="login_password" name="password" onChange={this.handlePasswordChange} placeholder="¥ourP4ssw0rd" ref={this.passwordRef} required type="password" value={password} />

                        <p><a id="password_reset" href="#reset">Forgot your password?</a></p>
                    </fieldset>

                    <button className={buttonClassNames} disabled={formIsSubmitting} id="login_submit">Log in</button>
                </form>
            </div>
        );
    }

    renderFooter() {
        return (
            <footer>
                <p>Need a XAPPmedia account?<br />
                <a href="#signup" id="signup_link">Create an account</a>.</p>
            </footer>
        );
    }

    renderGoogleButton() {
        const { formIsSubmitting } = this.state;
        const buttonClassNames = formIsSubmitting ? "social google is-working" : "social google";

        return (
            <button className={buttonClassNames} disabled={formIsSubmitting} onClick={this.handleGoogleLogin}>Log in with Google</button>
        );
    }

    renderPartialForm(key) {
        switch(key) {
            case STENTOR_AMAZON:
                return (
                    <>
                        <div id="social">
                            <p>The last time you visited, you logged in with your Amazon account.</p>
                            {this.renderAmazonButton(false)}
                        </div>
                        {this.renderClearButton()}
                    </>
                );
            case STENTOR_GOOGLE:
                return (
                    <>
                        <div id="social">
                            <p>The last time you visited, you logged in with your Google account.</p>
                            {this.renderGoogleButton(false)}
                        </div>
                        {this.renderClearButton()}
                    </>
                );
            case STENTOR_USER_PASS:
                return (
                    <>
                        <div id="social">
                            <p>The last time you visited, you logged in with a username and password.</p>
                            {this.renderForm()}
                        </div>
                        {this.renderClearButton()}
                    </>
                );
            default:
                return this.renderAllLoginOptions();
        }
    }
}
