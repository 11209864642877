import React, { Component } from "react";
import { Login, Reset, Signup } from "./components";

import "./scss/style.scss";

class App extends Component {
    webAuth;

    constructor(props) {
        super(props);

        this.state = {
            currentPanel: this.getInitialView()
        }

        this.initializeWebAuth();

        this.handleHashChange = this.handleHashChange.bind(this);
    }

    componentDidMount() {
        window.addEventListener("hashchange", this.handleHashChange, false);
    }

    componentWillUnmount() {
        window.removeEventListener("hashchange", this.handleHashChange, false);
    }

    getInitialView() {
        if (window && window.location && window.location.search) {
            return window.location.search.indexOf("mode=signUp") !== -1 ? "signup" : "login"
        }
    }

    getWindowHash() {
        if (window && window.location && window.location.hash) {
            return window.location.hash.replace("#", "");
        }
    }

    handleHashChange() {
        if (window && window.location && window.location.hash) {
            this.setState({
                currentPanel: this.getWindowHash()
            });
        }
    }

    initializeWebAuth() {
        this.webAuth = window.StentorWebAuth
    }

    render() {
        const { currentPanel } = this.state;

        switch(currentPanel) {
            case "reset":
                return <Reset webAuth={this.webAuth} />;
            case "signup":
                return <Signup webAuth={this.webAuth} />;
            default:
                return <Login webAuth={this.webAuth} />;
        }
    }
}

export default App;
