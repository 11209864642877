import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

// included so that webpack will move them to the /dist folder
import "./img/favicon.png";
import "./img/spinner.gif";
import "./img/splash.png";
import "./img/xapp-logo-teal.svg";

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

console.log(`Build Target: ${process.env.REACT_APP_TENANT}`);
console.log(`Build Time: ${process.env.REACT_APP_BUILD_TIME}`);
